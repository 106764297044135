import { isNullOrEmpty, isString, joinClassNames } from "@common/lib/util"
import { Size } from "@common/types"
import { SelectOptions } from "@common/v2/dropdowns/types"

export function filterOptions(options: SelectOptions, query: string): SelectOptions {
  if (!query) return options

  const lowercasedQuery = query.toLowerCase()

  return Object.keys(options).reduce((filteredOptions: SelectOptions, optionKey) => {
    const option = options[optionKey]

    const isOptionMatch =
      (isString(option.display) &&
        option.display?.toLowerCase().includes(lowercasedQuery)) ||
      option.key?.toLowerCase().includes(lowercasedQuery)

    const filteredSubOptions = option.options
      ? filterOptions(option.options, query)
      : undefined

    if (isOptionMatch || !isNullOrEmpty(filteredSubOptions)) {
      filteredOptions[optionKey] = {
        ...option,
        options: isOptionMatch ? option.options : filteredSubOptions,
      }
    }

    return filteredOptions
  }, {})
}

export function getListedOptionClasses(
  active: boolean,
  disabled?: boolean,
  size: Size = "xs" as Size
) {
  return joinClassNames(
    "w-full justify-between select-none flex items-center pr-2 rounded-md hover:bg-muted",
    active ? "bg-muted text-primary" : "text-secondary",
    disabled ? "opacity-50 cursor-not-allowed" : " cursor-pointer ",
    size == "xs" ? "size-xs" : "size-sm"
  )
}
